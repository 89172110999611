<template>
  <div class="about">
    <div class="main">
      <div id="threeContained"></div>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three'
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'

export default {
  data() {
    return {
      // activeIndex:"1"
      scene: "",
      light: "",
      camera: "",
      controls: "",
      renderer: "",
      load: "",
      clock: "",
      mixer: ""
    }
  },
  props:[
      'fbxSrc'
  ],
  methods: {
    init() {
      let that = this;
      let container = document.getElementById('threeContained')
      //创建场景
      that.scene = new THREE.Scene();
      that.scene.background = new THREE.Color(0x4562d6);
      //创建相机
      that.camera = new THREE.PerspectiveCamera(
          45,
          window.innerWidth / window.innerHeight,
          0.1,
          2000
      );
      //定位相机，并且指向场景中心
      that.camera.position.x = 30;
      that.camera.position.y = 30;
      that.camera.position.z = 170;
      that.camera.lookAt(that.scene.position)
      //显示三维坐标系
      let axes = new THREE.AxesHelper(100)
      //添加坐标系到场景中
      // that.scene.add(axes);
      //创建地面
      // const geometry = new THREE.BoxGeometry();
      // const material = new THREE.MeshDepthMaterial({color:0xffffff});
      //创建地面集合体
      let planeGeometry = new THREE.PlaneGeometry(100, 60);
      //给地面物体上色
      let planeMaterial = new THREE.MeshStandardMaterial({color: 0xcccccc});
      //创建地面
      let plane = new THREE.Mesh(planeGeometry, planeMaterial);
      plane.material.opacity = 0.6;
      plane.material.transparent = true;
      plane.rotation.x = -0.5 * Math.PI;
      plane.position.x = 0;
      plane.position.y = 0;
      plane.position.z = 0;
      plane.castShadow = true;
      //接收阴影
      plane.receiveShadow = true;
      // that.scene.add(plane);

      const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
      hemiLight.position.set(0, 1, 0);
      that.scene.add(hemiLight);

      const directionalLight2 = new THREE.DirectionalLight(0xffffff, 1);
      directionalLight2.position.set(1,0.25,0);
      // //设置相机渲染面积
      //   directionalLight2.shadow.camera.near = 0.01;
      //   directionalLight2.shadow.camera.far = 60;
      //   directionalLight2.shadow.camera.top = 22;
      //   directionalLight2.shadow.camera.bottom = -22;
      //   directionalLight2.shadow.camera.left = -35;
      //   directionalLight2.shadow.camera.right = 35;
      //   // //设置阴影分辨率
      //   directionalLight2.shadow.mapSize.width = 2048;  // default
      //   directionalLight2.shadow.mapSize.height = 2048; // default
      //   //阴影限制
      //   directionalLight2.shadow.radius = 1;
      that.scene.add(directionalLight2);

      const ambientLight = new THREE.AmbientLight(0x404040, 2);
      that.scene.add(ambientLight);

      //stats
      // that.stats = new Stats();
      // container.appendChild(that.stats.dom);

      //材质
      // const normal = new THREE.TextureLoader().load('./dm.jpg');

      //model
      that.loader = new FBXLoader();
      // let modelSrc = require('../../../public/mopan.fbx');
      let modelSrc = 'https://gsmm.xcforest.com/'+this.fbxSrc
      // console.log(modelSrc)
      that.loader.load(modelSrc, function (object) {
        // console.log(object);
        object.traverse(function (child) {
          // console.log(child);
          if(child instanceof THREE.Mesh){
                 child.material.emissive=new THREE.Color(2,2,1);
                //  child.material.emissive=child.material.color;
                 child.material.emissiveIntensity=1;
                 child.material.emissiveMap=child.material.map;

        }
          // if (child.isMesh) {
          //   child.material.specular.setScalar(0.1);
          //   child.material.normalMap = normal;
          // }
        });

        // object.mixer = new THREE.AnimationMixer(object)
        // let mixers = []
        // mixers.push(object.mixer)
        // let action = object.mixer.clipAction(object.animations[0])
        // action.play()
        // object.scale.multiplyScalar(2)
        // that.scene.add(object)

        // console.log(object);
        // let action = mixer.clipAction(object.animations[0])
        // action.play()

        // function render(){
        //   renderer.render(that.scene,that.camera)
        //   requestAnimationFrame(render)
        //   if(mixer !== null){
        //     mixer.update(clock.getDelta())
        //   }
        // }
        //
        object.scale.set(0.01, 0.01, 0.01);
        that.scene.add(object);
        that.animate();
      });

      //创建渲染器
      that.renderer = new THREE.WebGLRenderer();
      that.renderer.setPixelRatio(window.devicePixelRatio);
      //设置渲染器初始颜色
      that.renderer.setClearColor(new THREE.Color(0xeeeeee));
      //设置输出canvas画面的大小
      that.renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(that.renderer.domElement);

      const controls = new OrbitControls(that.camera, that.renderer.domElement);
      controls.target.set(0, 5, 0);
      //上下翻转的最大角度
      controls.maxPolarAngle = 1.5;
      //上下翻转的最小角度
      controls.minPolarAngle = 0.3;
      controls.update();

      window.addEventListener("resize", that.onWindowResize);

    },
    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize(window.innerWidth, innerHeight);
    },
    animate() {+
      requestAnimationFrame(this.animate);
      const delta = this.clock.getDelta();
      if (this.mixer) this.mixer.update(delta);
      this.renderer.render(this.scene, this.camera);
      // this.stats.update();
    },
    selectObject(event) {
      // debugger

      if (event.button != 0) return;

      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = (event.clientY / window.innerHeight) * 2 + 1;

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse,this.camera);

      const intersected = raycaster.intersectObjects(
          this.scene.children,
          false
      );
      console.log(intersected);
      if(intersected.length){
        const found = intersected[0];
        const faceIndex = found.faceIndex;
        const geometry = found.object.geometry;
        console.log(this.load);

        const modelID = found.object.modelID;
      }
    }
  },
  mounted() {
    this.clock = new THREE.Clock();
    setTimeout(()=> {
      this.init();
    },100)
    this.animate();
    window.onpointerdown = this.selectObject;
  }
}

</script>

<style scoped lang="less">

/deep/ .el-menu {
  border-bottom: 0;
}

.about {
  height: 100vh;

  .top-left {
    width: 200px;
  }

  .top-center {
    width: calc(100% - 400px);
  }

  .top-right {
    width: 200px;
  }
}

.top {
  height: 70px;
  display: flex;
  background-color: #0e243b;
}

.main {
  height: 100vh;
  width: 100%;
}
.bottm-button{
  position: absolute;
  width: 100%;
  height: 60px;
  background: rgba(44, 62, 80, 0.5);
  bottom: 1px;
}
.left-data{
  width: 400px;
  height: calc(100vh - 120px);
  position: absolute;
  left: 1px;
  bottom: 60px;
  background: rgba(33, 44, 105, 0.5);
}
</style>
