<template>
  <div class='explain-view'>

    <fbxShow :fbxSrc="fbxSrc"></fbxShow>

    <audio ref="mediaAudio" id="audio-media" @ended="setMediaState" autoplay></audio>
    <div class="slider-view" :class="{ 'show-slider': isShow }" v-loading="playLoading" element-loading-text="音频加载中...">
      <div class="slider-left">
        <!-- 左侧动态背景图 -->
        <img class="left-menu-bg" :src="isShow ? menuBgs[readIndex] : defaultBg" alt="" />
        <!-- 切换展示隐藏的按钮 -->
        <span class="slider-move" @click="changeShow"></span>
        <span class="menu-btn menu-story" @click="selectMenu(0)">
          <span>古树故事</span>
        </span>
        <span class="menu-btn menu-history" @click="selectMenu(1)">
          <span>历史文化</span>
        </span>
        <span class="menu-btn menu-value" @click="selectMenu(2)">
          <span>古树价值</span>
        </span>
      </div>
      <div class="slider-right">
        <div class="content-title">
          <span>{{ treeTitle }}</span>
          <img class="volum-img" :src="isPlay ? laba:jingyin" @click="playMedia" alt="" />
        </div>
        <p class="dscription">{{ readText }}</p>
        <video ref="mediaVideo" class="vjs-custom-skin" :loop="true">
          <source src="../../assets/041701.webm" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import menubg1 from '../../assets/u2150.png'
import menubg2 from '../../assets/u2162.png'
import menubg3 from '../../assets/u2174.png'
import menubg0 from '../../assets/u2187.png'
import laba from '../../assets/laba.png'
import jingyin from '../../assets/jingyin.png'
import fbxShow from "./fbxShow";

import fileDownload from 'js-file-download'

export default {
  name: '',
  data() {
    return {
      isPlay: false,
      defaultBg: menubg0,
      menuBgs: [menubg1, menubg2, menubg3],
      readText: [
        '千年银杏树 银杏树俗称白果树、公孙树。在阳台宫北5公里处华盖峰下紫微宫前有一棵千年银杏树，树高35.5米，树围9.1米，人称“七搂八扎一媳妇”。树干挺拔，枝叶茂密，树冠投影面积850余平方米，树龄近4000年。不仅为山东省罕见的名木古树，也是全国五大银杏树之一，被誉为“中国植物活化石”。',
        '莒县著名的千年银杏树，距今已有4000年历史，位于浮来山定林寺院内。该树下鲁、莒两国国君曾结成友好同盟。刘勰在此写出了世界上第一部文学评论巨著《文心雕龙》。定林寺千年银杏树下进月洞门，便见一清雅小院，院内垂槐罩地，青桐参天。桐槐深处有一幢二层小楼———“校经楼”。关于这座校经楼，《南史》载：“定林寺经藏，勰所也”，此楼就是当年刘勰校经藏书之处。刘勰于此，耳听晨钟暮鼓，埋头校经，直至圆寂，埋骨塔林。',
        '银杏性平，味甘苦涩，有小毒；入肺、肾经。 [5]  明代《本草纲目》中记载，银杏“熟食温肺益气，定喘嗽，缩小便，止白浊；生食降痰消毒、杀虫。”说明银杏果在治疗咳嗽、哮喘、遗精遗尿、白带方面具有独特的效果。通过对白果药用成分分析得知，果肉内含黄酮、内酯、白果酸、白果醇、白果酚、鞣酸、抑菌蛋白及多糖等有效成分，具有抑制真菌、抗过敏、通畅血管、改善大脑功能、延缓老年人大脑衰老、增强记忆能力、治疗老年痴呆症和脑供血不足等功效。除此以外，银杏还具有耐缺氧、抗疲劳和延缓衰老的作用。'
      ],
      readIndex: 0,
      playLoading: false,
      isShow: false,
      laba,
      jingyin,
      treeTitle:'',
      fbxSrc:''
    }
  },
  components:{
    fbxShow
  },
  created() {
    const id = this.$route.query.id
    this.getTreeInfo(id)
  },
  methods: {
    async getTreeInfo(id){
      const res = await this.request('/model/query',{ params: { imgId: id } })
      console.log(res)
      if(res.status == 200){
        this.readText = res.data.img_info.description
        this.treeTitle = res.data.img_info.title
        this.fbxSrc = res.data.model_src
        // fileDownload(res.data.model_src,'111.fbx')
      }
    },
    speckText() {
      const that = this
      const path = window.location.origin + this.$route.fullPath
      if (this.$refs.mediaAudio.src && this.$refs.mediaAudio.src !== path) {
        this.$refs.mediaAudio.play()
        this.$refs.mediaVideo.play()
      } else {
        this.playLoading = true
        const param = {
          tex: this.readText,
          // tex: this.readText[this.readIndex],
          tok: '24.fac65d90ab644f13a16c36b7faed00c4.2592000.1652941639.282335-25992404',
          spd: 5,
          pit: 5,
          vol: 15,
          per: 5003
        };
        const url = "http://tsn.baidu.com/text2audio";
        let xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        // 创建form参数
        let data = {};
        for (let p in param) {
          data[p] = param[p]
        }
        // 赋值预定义参数
        data.cuid = data.cuid || data.tok;
        data.ctp = 1;
        data.lan = data.lan || 'zh';
        //以上为封装请求的参数
        // 序列化参数列表
        let fd = [];
        for (let k in data) {
          fd.push(k + '=' + encodeURIComponent(data[k]));
        }
        xhr.responseType = 'blob';
        xhr.send(fd.join('&'));
        //请求返回结果处理
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            if (xhr.status == 200) {
              console.log(xhr)
              if (xhr.response.type == 'audio/mp3') {
                that.playLoading = false
                //创建audio对象进行播报
                that.$refs.mediaAudio.setAttribute('src', URL.createObjectURL(xhr.response))
                that.$refs.mediaAudio.play()
                that.$refs.mediaVideo.play()
              } else {
                //这里如果百度语音合成的接口返回类型不是audio/mp3，是json的话，代表你的参数有问题，返回结果失败，具体可以查看api文档
                alert("调用语音合成接口token已失效!");
              }
            } else {
              alert("语音合成接口调用失败!");
            }
          }
        }
      }
    },
    playMedia() {
      if (this.isPlay) {
        this.$refs.mediaVideo.pause()
        this.$refs.mediaAudio.pause()
      } else {
        this.speckText()
      }
      this.isPlay = !this.isPlay
    },
    setMediaState() {
      this.isPlay = false
      this.$refs.mediaVideo.pause()
      this.$refs.mediaVideo.currentTime = 0
      this.$refs.mediaAudio.currentTime = 0
    },
    changeShow() {
      this.isShow = !this.isShow
    },
    selectMenu(val) {
      this.readIndex = val
      this.isPlay = false
      this.$refs.mediaAudio.pause()
      this.$refs.mediaVideo.pause()
      this.$refs.mediaVideo.currentTime = 0
      this.$refs.mediaAudio.currentTime = 0
      this.$refs.mediaAudio.setAttribute('src', '')
    }
  }
}
</script>

<style lang='less' scoped>
.explain-view {
  position: relative;
  width: 100%;
  height: 800px;
  overflow: hidden;

  .slider-view {
    position: absolute;
    top: 150px;
    right: -360px;
    z-index: 1;
    transition: all ease 0.5s;
    display: flex;

    .slider-left {
      width: 48px;
      height: 504px;
      padding: 2px;
      display: flex;
      flex-direction: column;
      position: relative;

      .left-menu-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }

      .slider-move {
        width: 100%;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;
        font-size: 20px;
        position: relative;
        z-index: 2;
      }

      .menu-btn {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: relative;
        z-index: 2;

        span {
          width: 16px;
          font-size: 16px;
          line-height: 20px;
          color: #ffcc00;
        }
      }

      .menu-story {
        height: 146px;
        margin-bottom: 6px;
      }

      .menu-history {
        height: 146px;
        margin-bottom: 7px;
      }

      .menu-value {
        height: 145px;
      }
    }

    .slider-right {
      width: 360px;
      height: 504px;
      background: url('../../assets/u2151.png') 0 0 no-repeat;
      background-size: 100% 100%;
      padding: 20px 15px;
      position: relative;

      .content-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        span {
          font-size: 16px;
          line-height: 20px;
          color: #fff;
        }

        .volum-img {
          // width: 23px;
          width: 20px;
          height: 20px;
          margin-left: 10px;
          cursor: pointer;
        }

        .ml-2 {
          margin-left: 10px;
        }
      }

      .dscription {
        font-size: 14px;
        line-height: 30px;
        color: #fff;
      }

      .vjs-custom-skin {
        position: absolute;
        right: 0;
        bottom: -120px;
        z-index: 1;
        width: 210px;
        height: 292px;
        background: transparent;
      }
    }
  }

  .show-slider {
    right: 0;
  }
}
</style>
